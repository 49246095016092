<template>
	<v-card class="mx-auto mb-5" max-width="1200" rounded="xl">
		<v-toolbar>
			<v-avatar size="45px" class="mr-2">
				<v-img alt="Avatar" :src="notice.author.imgURL" />
			</v-avatar>
			<v-toolbar-title>
				<div class="d-flex flex-column">
					<span class="title">{{ notice.author.name }}</span>
					<span class="subtitle-1">{{ notice.parent }}</span>
				</div>
			</v-toolbar-title>
		</v-toolbar>

		<v-card-title>{{ notice.title }}</v-card-title>
		<v-card-subtitle>
			<time :datetime="notice.timestamp">{{ humanTime }}</time>
		</v-card-subtitle>
		<v-card-text class="body-1" style="word-break: break-all; white-space: pre-line">
			{{ notice.content }}
		</v-card-text>

		<v-card-actions>
			<v-btn text small icon @click="callAproveNotice(true)">
				<v-icon>{{ aproved ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}</v-icon>
			</v-btn>
			<v-badge class="mr-2" :content="thumbsUp ? thumbsUp : '0'" :value="thumbsUp ? thumbsUp : '0'" inline overlap />

			<v-btn text small icon @click="callAproveNotice(false)">
				<v-icon>{{ disaproved ? 'mdi-thumb-down' : 'mdi-thumb-down-outline' }}</v-icon>
			</v-btn>
			<v-badge :content="thumbsDown ? thumbsDown : '0'" :value="thumbsDown ? thumbsDown : '0'" inline overlap />
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'FeedNotice',
	props: {
		notice: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			aproved: this.notice.aproved,
			disaproved: this.notice.disaproved,
			thumbsUp: this.notice.thumbsUp,
			thumbsDown: this.notice.thumbsDown,
			comments: this.notice.comments,
			editing: false,
			title: this.notice.title,
			content: this.notice.content,
			public: this.notice.public,
		}
	},
	computed: {
		humanTime() {
			return this.$moment(this.notice.timestamp).fromNow()
		},
		...mapGetters({
			setupInfo: 'user/setupInfo',
		}),
	},
	methods: {
		callAproveNotice(toBeAproved) {
			if ((this.aproved && toBeAproved) || (this.disaproved && !toBeAproved)) {
				this.undoAproveNotice({ notice: this.notice }).then((data) => {
					if (data.success) {
						this.aproved = false
						this.disaproved = false
						if (toBeAproved) {
							this.thumbsUp -= 1
						} else {
							this.thumbsDown -= 1
						}
					}
				})
			} else if (this.setupInfo.studies.verifiedStudies) {
				this.aproveNotice({ aproved: toBeAproved, notice: this.notice }).then((data) => {
					if (data.success) {
						if (toBeAproved) {
							if (this.disaproved) {
								this.thumbsDown -= 1
							}
							this.aproved = true
							this.disaproved = false
							this.thumbsUp += 1
						} else {
							if (this.aproved) {
								this.thumbsUp -= 1
							}
							this.aproved = false
							this.disaproved = true
							this.thumbsDown += 1
						}
					}
				})
			} else {
				console.log('needs logged in user')
			}
		},
		...mapActions('feed', ['aproveNotice', 'undoAproveNotice']),
	},
}
</script>
